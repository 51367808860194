@font-face {
  font-family: "Circular";
  src: url("./fonts/CircularStd-Medium.otf");
}

body {
  font-family: "Circular" !important;
  letter-spacing: 0.4px !important;
}

